<template>
    <div>
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper" id="content-wrapper">
                <expand-filter
                    ref="expandFilter"
                    hasQueryFiltering="QueryFiltering"
                    :formData="formData"
                    :closeWidth="closeWidth"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @datePickerBlur="datePickerBlur"
                    @inputChange="inputChange"
                    @changeCascader="changeSel"
                    @dataChange="clickBtn({item:{fn: 'primary'}})"
                >
                    <query-filtering
                        :key="+new Date()"
                        ref="QueryFiltering"
                        slot="screening"
                        :treeScreeningData="treeScreeningData"
                        :chaeckIdArr="chaeckIdArr"
                        :restaurantes="restaurantes"
                        @determine="handleSubmitData"
                    ></query-filtering>

                    <!-- <el-form-item
                        slot="slotCom"
                        label=""
                    >
                        <el-tooltip class="item" effect="dark" content="同时筛选负责班级和任课班级" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>

                    </el-form-item>     -->

                    <el-button
                        slot="other-btn"
                        style="
                            display: block;"
                        class="hover"
                        plain
                        @click="add"
                        v-hasPermi="addBtn"
                        type="primary"
                    >添加</el-button>
                    <div slot="dropdown"
                        style="margin-left: 10px;
                        flex: 1;
                        display: flex;
                        justify-content: flex-end"
                    >
                        <el-dropdown
                            trigger="click"
                            style="background-color: #ffffff;
                            margin-left:10px;"
                            v-hasPermi="moreOptBtn"
                        >
                            <el-button
                                type="enquiry"
                            >
                                更多操作<i class="el-icon-caret-bottom el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu
                                slot="dropdown"
                                style="background-color: #ffffff;
                                text-align:center;"
                            >
                                <el-dropdown-item>
                                    <el-popover
                                        v-if="basicInfoEditFlag || moreInfoEditFlag"
                                        placement="right-start"
                                        trigger="hover"
                                        width="110"
                                        popper-class="teacher-import-popover"
                                    >
                                        <el-button
                                            v-if="basicInfoEditFlag"
                                            type="text"
                                            class="hover import-button"
                                            @click="importAll"
                                        >基础信息</el-button>
                                        <el-button
                                            v-if="moreInfoEditFlag"
                                            type="text"
                                            class="hover import-button"
                                            @click="importMore"
                                        >更多信息</el-button>
                                        <el-button
                                            v-hasPermi="importBtn"
                                            slot="reference"
                                            style="width:100%"
                                            type="text"
                                            class="hover"
                                        >
                                            导入
                                        </el-button>
                                    </el-popover>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        class="hover"
                                        @click="exportAll(1)"
                                        v-hasPermi="exportBtn"
                                        :loading="exportLoading"
                                    >
                                        导出信息
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        class="hover"
                                        @click="createHeadImg"
                                        v-hasPermi="createHeadBtn"
                                        :loading="createHeadImgButtonLoading"
                                    >
                                        生成头像
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        class="hover"
                                        @click="exportHeadImg"
                                        v-hasPermi="exportHeadBtn"
                                    >
                                        导出头像
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        class="hover"
                                        @click="editInfoTemplate"
                                        v-hasPermi="['teacherBase:documentTemplate']"
                                    >
                                        编辑模版
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        class="hover"
                                        @click="fillFormLinkShare"
                                        v-hasPermi="['teacherBase:documentShar']"
                                    >
                                        个人权限
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        class="hover"
                                        @click="dataUpdate"
                                        v-hasPermi="['teacherBase:infoUpdate']"
                                    >
                                        信息更新
                                    </el-button>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </expand-filter>
                <table-data
                    v-if="table_config.thead.length > 0"
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :to-top="false"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                    :density="tableDensity"
                    :rowStyle="rowStyle"
                    @linkClick="handlerTableLinkClick"
                    @dropDownClick="dropDownClick(arguments)"
                    @handleSelectionChange="handleSelectionChange"
                    @tableSetting="showTableSetting">
                    <template v-slot:operation="slotData">
                        <el-button
                            v-hasPermi="['teacherBase:edit']"
                            type="text"
                            class="primary"
                            :disabled="slotData.data.status == 2"
                            @click="edit(slotData.data, 'edit')"
                        >编辑</el-button>
                        <el-button
                            v-hasPermi="['teacherBase:remove']"
                            type="text"
                            class="error"
                            :disabled="slotData.data.status == 2"
                            @click="deleteInfo(slotData.data, 'edit')"
                        >删除</el-button>

                    </template>
                </table-data>

                <div class="pagination-box">
                    <div class="pagination-left">
                        <span v-if="tableListSel.length > 0" class="pagination-left-label">批量操作</span>
                        <template v-if="tableListSel.length > 0">
                            <el-button
                                @click="acts(1)"
                                class="custom-button-primary"
                            >
                                在职
                            </el-button>
                            <el-button
                                @click="acts(2)"
                                class="custom-button-danger"
                            >
                                离职
                            </el-button>
                            <el-button
                                class="custom-button-default"
                                @click="exportAll(2)"
                                v-hasPermi="exportBtn"
                                :loading="exportLoading"
                            >
                                导出信息
                            </el-button>
                            <el-button
                                class="custom-button-default"
                                @click="createHeadImg"
                                v-hasPermi="createHeadBtn"
                                :loading="createHeadImgButtonLoading"
                            >
                                生成头像
                            </el-button>
                            <el-button
                                class="custom-button-default"
                                @click="exportHeadImg"
                                v-hasPermi="exportHeadBtn"
                            >
                                导出头像
                            </el-button>
                        </template>
                    </div>

                    <pagination
                        v-if="tableListSel.length === 0"
                        ref="pagination"
                        :key="paginationIdx"
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="changeGetList"
                    />
                </div>

                <dialog-wrapper
                    :dialogObj="dialogObj"
                    @handleClose="handleClose"
                >
                    <el-form
                        :model="ruleForm"
                        :rules="rules"
                        ref="ruleForm"
                        label-position="left"
                    >
                        <div class="lt">
                            <el-form-item
                                label="教师头像"
                                prop="headImg"
                            >
                                <upload
                                    v-model="ruleForm.headImg"
                                    :positionStatus="true"
                                ></upload>
                            </el-form-item>
                        </div>
                        <div class="rt">
                            <el-form-item
                                label="教师姓名"
                                prop="teacherName"
                                class="w2"
                            >
                                <el-input
                                    v-model="ruleForm.teacherName"
                                    placeholder="请输入"
                                    class="labelWidth"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="手机号"
                                prop="phone"
                                class="w2"
                            >
                                <el-input
                                    v-model="ruleForm.phone"
                                    placeholder="请输入"
                                    class="labelWidth"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="工号"
                                prop="onlyCode"
                                class="w2"
                            >
                                <el-input
                                    v-model="ruleForm.onlyCode"
                                    placeholder="请输入"
                                    class="labelWidth"
                                ></el-input>
                            </el-form-item>
                            <div class="w2">
                                <el-form-item
                                    label="性别"
                                    prop="sex"
                                    style="margin-top: 10px"
                                    label-width="107px"
                                >
                                    <el-radio
                                        v-model="ruleForm.sex"
                                        label="1"
                                        >男</el-radio
                                    >
                                    <el-radio
                                        v-model="ruleForm.sex"
                                        label="2"
                                        >女</el-radio
                                    >
                                </el-form-item>
                                <el-form-item
                                    label="是否任课教师"
                                    prop="isSubjectTeacher"
                                >
                                    <el-radio
                                        v-model="ruleForm.isSubjectTeacher"
                                        label="1"
                                        >是</el-radio
                                    >
                                    <el-radio
                                        v-model="ruleForm.isSubjectTeacher"
                                        label="2"
                                        >否</el-radio
                                    >
                                </el-form-item>
                            </div>
                            <el-form-item
                                label="所属部门"
                                prop="orgList"
                                class="w2"
                            >
                                <m-tree-select
                                    :key="dialogObj.key"
                                    ref="treeSelect1"
                                    multiple
                                    v-model="ruleForm.orgList"
                                    :checkStrictly="true"
                                    :collapse-tags="true"
                                    :data="options"
                                ></m-tree-select>
                                <div class="tips">
                                    无部门可选？请点击
                                    <el-button
                                        type="text"
                                        @click="
                                            $router.push(
                                                '/summary/setting/summaryStructure',
                                            )
                                        "
                                        >部门设置</el-button
                                    >
                                    进行添加
                                </div>
                            </el-form-item>
                            <div class="item-wrapper">
                                <el-form-item
                                    label="任课班级"
                                    prop="subjectClassList"
                                    class="w2"
                                    v-if="ruleForm.isSubjectTeacher === '1'"
                                >
                                    <m-tree-select
                                        v-if="dialogObj.dialogVisible"
                                        ref="treeSelect2"
                                        filterable
                                        multiple
                                        v-model="ruleForm.subjectClassList"
                                        :filterFn="filterFn"
                                        :collapse-tags="true"
                                        :checkStrictly="false"
                                        :data="options2"
                                    ></m-tree-select>
                                    <div class="tips">
                                        无班级可选？请点击
                                        <el-button
                                            type="text"
                                            @click="
                                                $router.push(
                                                    '/summary/setting/summaryStructure',
                                                )
                                            "
                                            >班级设置</el-button
                                        >
                                        进行添加
                                    </div>
                                </el-form-item>
                                <el-form-item
                                    label="任教科目"
                                    prop="subject"
                                    class="w2"
                                    v-if="ruleForm.isSubjectTeacher === '1'"
                                >
                                    <el-select
                                        v-model="ruleForm.subject"
                                        placeholder="请选择"
                                        style="width: 280px"
                                    >
                                        <el-option
                                            v-for="it in subjects"
                                            :key="it.value"
                                            :label="it.label"
                                            :value="it.value"
                                        />
                                    </el-select>
                                    <div class="tips">
                                        无科目可选？请点击
                                        <el-button
                                            type="text"
                                            @click="
                                                $router.push(
                                                    '/system/baseSetting/SystemDict?dictKey=teachingSubjects',
                                                )
                                            "
                                            >科目设置
                                        </el-button>
                                        进行添加
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="item-wrapper">
                                <el-form-item
                                    label="是否班主任"
                                    prop="isHeadmaster"
                                    class="w2"
                                >
                                    <div
                                        style="
                                            display: inline-block;
                                            width: 100%;
                                        "
                                    >
                                        <el-radio
                                            v-model="ruleForm.isHeadmaster"
                                            label="1"
                                            >是</el-radio
                                        >
                                        <el-radio
                                            v-model="ruleForm.isHeadmaster"
                                            label="2"
                                            >否</el-radio
                                        >
                                    </div>
                                </el-form-item>
                                <el-form-item
                                    label="班主任负责班级"
                                    prop="manageClassList"
                                    class="w2"
                                    v-if="ruleForm.isHeadmaster === '1'"
                                >
                                    <m-tree-select
                                        v-if="dialogObj.dialogVisible"
                                        ref="treeSelect3"
                                        filterable
                                        multiple
                                        v-model="ruleForm.manageClassList"
                                        :filterFn="filterFn"
                                        :collapse-tags="true"
                                        :checkStrictly="false"
                                        :data="options2"
                                    ></m-tree-select>
                                </el-form-item>
                            </div>
                            <div class="item-wrapper">
                                <el-form-item
                                    label="职务"
                                    prop="post"
                                    class="w2"
                                >
                                    <div style="width: 300px">
                                        <el-select
                                            ref="postSelect"
                                            v-model="ruleForm.post"
                                            placeholder="请选择职务"
                                            @change="postName"
                                            multiple
                                            :multiple-limit="5"
                                            filterable
                                            clearable
                                            class="labelWidth"
                                            style="width: 280px"
                                        >
                                            <el-option
                                                v-for="it in postList"
                                                :key="it.value"
                                                :label="it.label"
                                                :value="it.value"
                                            />
                                        </el-select>
                                        <div class="tips">
                                            无职务可选？请点击
                                            <el-button
                                                type="text"
                                                @click="
                                                    $router.push(
                                                        '/system/baseSetting/SystemDict?dictKey=teachingPost',
                                                    )
                                                "
                                                >职务设置
                                            </el-button>
                                            进行添加
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item
                                    label="可查看班级数据"
                                    prop="viewList"
                                    class="w2"
                                >
                                    <m-tree-select
                                        v-if="dialogObj.dialogVisible"
                                        ref="treeSelect4"
                                        filterable
                                        multiple
                                        v-model="ruleForm.viewList"
                                        :filterFn="filterFn"
                                        :collapse-tags="true"
                                        :checkStrictly="false"
                                        :data="options2"
                                    ></m-tree-select>
                                </el-form-item>
                            </div>
                        </div>

                        <div class="tac">
                            <xk-button
                                @click="submitForm('ruleForm')"
                                type="primary"
                                v-if="pageType === 'add'"
                            >
                                确定
                            </xk-button>
                            <xk-button
                                @click="submitForm('ruleForm')"
                                type="primary"
                                v-if="pageType === 'edit'"
                            >
                                确定
                            </xk-button>
                            <xk-button
                                @click="handleClose('ruleForm')"
                                type="info"
                                style="margin-left: 24px"
                                >取消
                            </xk-button>
                        </div>
                    </el-form>
                </dialog-wrapper>

                <dialog-wrapper
                    :dialogObj="importDialogObj"
                    @handleClose="handleImportClose"
                >
                    <exam-import
                        ref="importRef"
                        :active="importActive"
                        :import-form="importForm"
                        :upload-excel-header="uploadExcelHeader"
                        :system-excel-header="systemExcelHeader"
                        :key-map="keyMap"
                        :import-info="importInfo"
                        :importLoading="importLoading"
                        :success-data="importSuccessData"
                        @uploadChange="importUploadChange"
                        @uploadRemove="importUploadRemove"
                        @next="handleImportNext"
                        @before="handleImportBefore"
                        @close="handleImportClose"
                        @importSelChange="importSelChange"
                        @download="downloadImportTemplate"
                        @save="handleImportSave"
                    >
                        <div></div>
                    </exam-import>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialogObj="importExistingDialogObj"
                    @handleClose="handleImportExistingClose"
                >
                    <ExamImportMore
                        ref="importRefMore"
                        :activeMore="activeMore"
                        :importMoreForm="importMoreForm"
                        :importMoreInfo="importMoreInfo"
                        :uploadExcelHeaderMore="uploadExcelHeaderMore"
                        :systemExcelHeaderMore="systemExcelHeaderMore"
                        :keyMapMore="keyMapMore"
                        :successDataMore="importSuccessDataMore"
                        @downloadMore="downloadMore"
                        @nextMore="nextMore"
                        @closeMore="closeMore"
                        @uploadChangeMore="uploadChangeMore"
                        @uploadRemoveMore="uploadRemoveMore"
                        @saveMore="saveMore"
                        @beforeMore="beforeMore"
                        @importSelChangeMore="importSelChangeMore"
                        @handlerSelectFileCategoryChange="
                            handlerSelectFileCategoryChange
                        "
                    />
                </dialog-wrapper>
                <!-- 生成头像弹框 -->
                <dialog-wrapper
                    :dialogObj="createHeadImgDialogObj"
                    @handleClose="handleHeadImgClose"
                >
                    <el-form
                        :model="headImgFormData"
                        :rules="headImgFormRules"
                        label-width="0px"
                        ref="formRef"
                        label-posiiton="right"
                    >
                        <el-form-item label="" prop="id">
                            <el-radio-group v-model="headImgFormData.id">
                                <el-radio
                                    v-for="item in headImgFileNameOptions"
                                    :key="item.id"
                                    :label="item.id"
                                >{{item.name}}</el-radio>
                            </el-radio-group>
                        </el-form-item>

				<div style="text-align: right;">
                		<el-button :loading="createHeadImgButtonLoading" @click="handleImgHeadSubmit" type="primary">确定</el-button>
                		<el-button @click="handleHeadImgClose">取消</el-button>
            	</div>
            </el-form>
                </dialog-wrapper>
                <!-- 表格设置面板 -->
                <el-drawer
                    class="table-setting"
                    :visible.sync="tableSettingShow"
                    :append-to-body="true"
                    size="320px"
                    direction="rtl"
                    @close="handlerTableSettingSave">
                    <table-setting
                        :density="tableDensity"
                        :draggingList="tableHeaderAll"
                        :disabledList="['teacherName', 'phone']"
                        @densityChange="handlerTableSettingDensityChange"
                        @reset="handlerTableSettingReset"
                        @sort="handlerTableSettingSort"
                        @close="handlerTableSettingClose"
                    ></table-setting>
                </el-drawer>

                <!-- 信息更新弹窗 -->
                <!-- 批量更新 -->
			<DialogWrapper
				:dialogObj="updateDialogObj"
				@handleClose="handleUpdateClose"
			>
				<div
					slot="title"
					style="position: relative"
				>
					<div style="text-align: center">{{ updateDialogObj.title }}</div>
				</div>
                <!-- 导入 -->
				<TeacherUpdate
					ref="updateRef"
					:active="updateActive"
					:options="updateOptions"
					:standard-options="standardOptions"
					:schools="updateSchools"
					:import-form="updateForm"
					:show-download="updateShowDownload"
					:upload-excel-header="updateUploadExcelHeader"
					:system-excel-header="updateSystemExcelHeader"
					:key-map="updateKeyMap"
					:import-info="updateInfo"
					:success-data="updateSuccessData"
					@download="downloadUpdateTemplate"
					@uploadChange="updateUploadChange"
					@uploadRemove="updateUploadRemove"
					@next="handleUpdateNext"
					@before="handleUpdateBefore"
					@close="handleUpdateClose"
					@importSelChange="updateSelChange"
					@headerTypeChange="updateHeaderTypeChange"
					@save="handleUpdateSave"
					@complete="handleUpdateComplete"
					v-loading="loading"
					element-loading-text="拼命加载中，请稍等"
				/>
			</DialogWrapper>

            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    MTreeSelect,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    throttle,
    debounce,
} from "common-local";
import TableData from "./Sub/TableData"
import {downloadFile, listToTree, hasPermission} from "@/libs/utils.js";

import {mapState} from "vuex";
import XLSX2 from "xlsx";
// 自定义颜色
import btnSty from "@/mixins/btnSty.js";
import OperationBtnView from "./Summary/OperationBtnView.vue";
import upload from "./Summary/upload.vue";
// 导入弹窗件
import examImport from "./Summary/ExamImport/index.vue";
import ExamImportMore from "./Summary/ExamImportMore/index.vue";
import {TestModel} from "@/models/test";
import ExpandFilter from "./Sub/ExpandFilter";
import {Desensitization} from "@/libs/desensitization.js";
import {SummaryTeacherBase} from "@/models/SummaryTeacherBase.js";
import QueryFiltering from "./Sub/QueryFiltering.vue";
import TableSetting from './Sub/TableSetting.vue';
import RecordsList from '@/libs/pageListOptimal.js';

// 信息更新弹窗
import TeacherUpdate from "./Summary/TeacherUpdate/index.vue"; // 信息更新弹窗

export default {
    mixins: [btnSty],
    inject: ['mainContainer'],
    props: ["query"],
    components: {
        examImport,
        ExamImportMore,
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        MTreeSelect,
        OperationBtnView,
        upload,
        ExpandFilter,
        QueryFiltering,
        TableSetting,
        TeacherUpdate
    },
    data() {
        return {
            roleList:[],//角色组下拉信息
        // 更新弹窗配置
			updateDialogObj: {
				title: "信息更新",
				dialogVisible: false,
				width: "900px",
			},
            // 更新步骤
			updateActive: 0,
            // 更新-下拉选项
			updateOptions: [
				{
					label: "姓名",
					value: "teacherName",
				},
				{
					label: "性别",
					value: "sex",
				},
				{
					label: "手机号",
					value: "phone",
				},
				{
					label: "工号",
					value: "onlyCode",
				},
				{
					label: "任教科目",
					value: "subject",
				},
				{
					label: "任教班级",
					value: "subjectClassName",
				},
				{
					label: "负责班级",
					value: "masterClassName",
				},
				{
					label: "任职部门",
					value: "dept",
				},
				{
					label: "物理卡号",
					value: "physicsNumber",
				},
				{
					label: "消费账号",
					value: "consumNumber",
				},
				{
					label: "职务",
					// value: 'studentStayType',
					value: "post",
				},
				{
					label: "班级权限",
					// value: 'studentStayType',
					value: "viewClassName",
				},
				{
					label: "用户角色",
					value: "userRoles",
				},
				{
					label: "部门权限",
					value: "deptPermissions",
				},
                {
					label: "车牌号",
					value: "carNumber",
				},
			],
            standardOptions: [
				{
					label: "手机号",
					value: "phone",
				},
				{
					label: "工号",
					value: "onlyCode",
				},
                
			],
            updateSchools:[],
			// 更新数据
			updateForm: {
				schoolId: "",
				standardTextList: [],
				standardValueList: [],
				renewTextList: [],
				renewValueList: [],
				file: "",
				errorHandle: "1", // 1继续执行  2终止导入
			},
            updateShowDownload: true,
           // 更新弹窗上传表头数据
			updateUploadExcelHeader: [],
            // 更新弹窗系统标准字段名称
			updateSystemExcelHeader: [],
            updateKeyMap: {},
            updateInfo: {
				tips: "上传的文件必须包含：",
				tips2: "直接上传已有的教师更新信息，下一步建立对应关系",
				downloadTemplate: "下载教师信息更新导入表",
				uploadText: "上传填好的教师信息更新表",
			},
            updateSuccessData: 0,
            loading: false,
            paginationIdx: 0,
            choseForm:[],  //
            importLoading: false, //是否显示进度条
            listQuery: {
                roleGroup:[],//角色组
                roleIdList:[], //角色组
                teacherName: "",
                schoolId: "",
                pageNum: 1,
                pageRow: 20,
                isHeadmaster: "",
                isSubjectTeacher: "",
                status: "",
                phone: "",
                onlyCode: "",
                sex: "",
                post: "",
                entryStartTime: "",
                entryEndTime: "",
                archivesCompleteness: "",
                // entryTime: []
            },
            props: {
                multiple: true,
                value: "id",
                label: "name",
                checkStrictly: true,
            },
            options: [],
            options2: [],
            subjects: [],
            total: 0,
            treeData: {
                type: "selectTree",
                label: "班级",
                value: "",
                placeholder: "请选择班级",
                show: true,
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                dataList: [],
                showAlways: true,
                filter(data) {
                    return data.organType != 1;
                },
            },
            treeData2: {
                type: "selectTree",
                label: "班级",
                value: "",
                placeholder: "请选择班级",
                show: false,
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                dataList: [],
                filter(data) {
                    return data;
                },
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [],
                btnList: [
                    // {
                    //     type: "primary",
                    //     text: "查询",
                    //     fn: "primary",
                    //     auth: ["teacherBase:list"],
                    // }
                ],
            },
            // 表格配置
            table_config: {
                thead: [],
                check: true,
                rowkey: "id",
                height: "",
                // isCloseRow: {key: "status", value: "2"},
            },
            // 表格数据
            table_data: [],
            // 弹出框配置
            dialogObj: {
                title: "添加组织",
                dialogVisible: false,
                width: "1000px",
            },
            dialogObj2: {
                title: "调班",
                dialogVisible: false,
                width: "30%",
            },
            defaultProps: {
                children: "children",
                label: "name",
            },
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,

            template_info: [],
            // 表头
            backgroundHeader: "#FAFBFC",
            // 表头字体
            headerColor: "#595959",
            // 新增
            ruleForm: {
                teacherName: "",
                remark: "",
                id: "",
                schoolId: "",
                phone: "",
                onlyCode: "",
                status: 1,
                sex: "1",
                classType: 1,
                post: "",
                postName: "",
                orgList: [],
                headImg: "",
                isHeadmaster: "2",
                isSubjectTeacher: "2",
                subjectClassList: [],
                manageClassList: [],
                viewList: [],
                subjectName: "",
                subject: "",
            },
            rules: {
                teacherName: [
                    {
                        required: true,
                        message: "请输入教师姓名",
                        trigger: ["change", "blur"],
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (
                                !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
                                    value,
                                )
                            ) {
                                callback("手机号不正确");
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                onlyCode: [
                    {
                        required: true,
                        message: "请输入工号",
                        trigger: "blur",
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                isSubjectTeacher: [
                    {
                        required: true,
                        message: "请选择是否是任课教师",
                        trigger: "change",
                    },
                ],
                isHeadmaster: [
                    {
                        required: true,
                        message: "请选择是否是班主任",
                        trigger: "change",
                    },
                ],
                orgList: [
                    {
                        required: true,
                        message: "请选择所属部门",
                        trigger: "blur",
                    },
                ],
                subjectClassList: [
                    {
                        required: true,
                        message: "请选择任教班级",
                        trigger: "blur",
                    },
                ],
                subject: [
                    {
                        required: true,
                        message: "请选择任教科目",
                        trigger: "blur",
                    },
                ],
                manageClassList: [
                    {
                        required: true,
                        message: "请选择班主任负责班级",
                        trigger: "blur",
                    },
                ],
            },
            pageType: "add",
            tableListSel: [],
            // 导入步骤
            importActive: 0,
            keyMap: {},
            // 上传表头数据
            uploadExcelHeader: [],
            // 系统标准字段名称
            systemExcelHeader: [
                {label: "姓名", required: true},
                {label: "性别", required: false},
                {label: "手机号", required: true},
                {label: "工号", required: true},
                {label: "任职部门", required: true},
                {label: "任教科目", required: false},
                {label: "任课班级", required: false},
                {label: "负责班级", required: false},
                {label: "职务", required: false},
                {label: "班级权限", required: false},
                {label: "系统登录账号", required: false},
                {label: "登录密码", required: false},
                {label: "消费账号", required: false},
                {label: "部门权限", required: false},
                {label: "用户角色", required: false},
                {label: "入职时间", required: false},
                {label: "序列号", required: false},
                {label: "车牌号", required: false},
            ],
            // 导入数据
            importForm: {
                file: "",
                errType: "1", // 1继续执行  2终止导入
                sameType: "1", // 1覆盖导入  2追加导入
            },
            // 导入弹窗配置
            importDialogObj: {
                title: "导入",
                dialogVisible: false,
                width: "900px",
            },
            tableJsonData: [],
            importInfo: {
                tips: "上传的文件必须包含：教师姓名（必填）、手机号（必填）、性别、所属部门（不填写默认可查看全部部门）",
                tips2: "直接上传已有的教师信息，下一步建立对应关系",
                downloadTemplate: "下载教师信息导入表",
                sameInfo: "覆盖导入：更新相同姓名和手机号老师的基础信息",
            },
            importSuccessData: 0,

            /**
             * 导入已有信息弹窗配置
             *
             * */
            importExistingDialogObj: {
                title: "导入已有档案信息",
                dialogVisible: false,
                width: "900px",
            },
            activeMore: 0, // 导入已有档案信息
            // 导入数据
            importMoreForm: {
                file: "",
                errType: "1", // 1继续执行  2终止导入
                archivesPersonType: "2", // 1学生档案  2教师档案
            },
            importMoreInfo: {
                tips: "选择上传模版内容，下载模版，根据模版内容填充数据，上传填写好的文件（如已有填写好的文件，可仅选择跟文档之中对应的模版，上传文件皆可）",
                options: [],
                value: "",
                downloadTemplate: "下载教师信息导入表",
                sameInfo: "覆盖导入：更新相同姓名和手机号老师的基础信息",
            },
            tableJsonDataMore: [],
            // 上传表头数据
            uploadExcelHeaderMore: [],
            systemExcelHeaderMore: [],
            keyMapMore: {},
            importSuccessDataMore: 0,
            treeScreeningData: [],  // 筛选项
            chaeckIdArr: [], // 已勾选的  isOff为1
            restaurantes: [],
            // 表格设置面板
            tableSettingShow: false,
            tableDensity: '1',
            tableHeaderAll: [],
            // 筛选的选项信息
            baseScreeningList:[],
            postList: [],
            closeWidth: 1450,
            showExpandFilter: false,
            exportLoading: false,
            // 生成头像弹框配置
            createHeadImgDialogObj: {
                title: "选择头像命名格式",
                dialogVisible: false,
                width: "600px",
            },
            // 头像命名格式
            headImgFileNameOptions:[
                {
                    id:"name",
                    name:"姓名"
                },
                {
                    id:"nameAndCode",
                    name:"姓名-工号"
                },
                {
                    id:"nameAndPhone",
                    name:"姓名-手机号"
                },
            ],
            headImgFormData:{
                id:"name",
                isStrongDown:"2",//是否强制重新生成，1强制重新生成，2不重新生成
            },
            headImgFormRules:{},
            createHeadImgButtonLoading:false,
            rowStyle: {
                height: '48px'
            },
            summaryTeacherEditId: '0304010106', // 教工信息编辑权限id
            editBasicId: '34b6ee9902b54983807b9be416ab6ebe', // 基础信息编辑权限id
            editMoreId: '9f941adc1f3b4f72a46edf53c61a34a6', // 更多信息编辑权限id
            basicInfoEditFlag: false, // 基础信息编辑权限
            moreInfoEditFlag: false, // 更多信息编辑权限
            checkedAll: false,
            indeterminate: false,
        };
    },
    computed: {
        ...mapState({
            templateInfo: (state) => state.templateInfo,
            schoolId: state => state.schoolId,
            segmentPermList: state => state.permission.segmentPermList,
            segmentMenuList: state => state.permission.segmentMenuList,
        }),
        //导入、导出、添加按钮加权限
        importBtn() {
            return ["teacherBase:import"];
        },
        exportBtn() {
            return ["teacherBase:export"];
        },
        createHeadBtn() {
            return ["teacherBase:generate:head"];
        },
        exportHeadBtn() {
            return ["teacherBase:export:head"];
        },
        addBtn() {
            return ["teacherBase:add"];
        },
        moreOptBtn () {
            return ["teacherBase:import", "teacherBase:export","teacherBase:generate:head","teacherBase:export:head",'teacherBase:documentTemplate','teacherBase:documentShar','teacherBase:infoUpdate'];
        },
        imgErrorAdr () {
            return require('@/assets/images/imgErrorAdr.png')
        }

    },
    async created() {
        await this.init();
        // 路由参数不该携带参数 清空由于路由跳转携带的参数
        if (this.$route.query.formData) {
            this.$router.push({query: {}});
        }
        // 获取保存的查询条件
        if (JSON.stringify(this.query) !== "{}") {
            this.formData = this.query.formData;
            this.listQuery = this.query.listQuery;
        }
        this.ruleForm.schoolId = this.listQuery.schoolId = this.$store.state.schoolId;
        this.getFileCateGory();
        this.getOrgList();
        this.getClassList();
        this.getDictList().then((r) => this.getList());
        this.getRoleList();
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                console.log(document.getElementById("table"),'document.getElementById("table")')
                if(document.getElementById("table")) {
                    this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
                    console.log(this.table_config.height,'this.table_config.height==1')
                }
                window.onresize = debounce(() => {
                    if (!document.getElementById("table")) return;
                    const height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
                    this.table_config.height = height.toString();
                    console.log(this.table_config.height,'this.table_config.height==2')
                    this.$refs.QueryFiltering && this.$refs.QueryFiltering.bindResize()
                }, 200);
            }, 100);
        });
        this.handlerWindowSize()
        console.log(this.formData, "formData");
    },
    methods: {
        // ----------------- 信息更新 -------------------------
        dataUpdate(){
            this.updateDialogObj.dialogVisible = true;
        },
        /**
		 * handleUpdateClose       更新弹窗关闭
		 * downloadUpdateTemplate  下载学生成绩模板
		 * updateUploadChange      已上传文件赋值
		 * updateUploadRemove      删除已上传文件
		 * handleUpdateNext        下一步
		 * updateGetKeyMap         获取keyMap
		 * handleUpdateBefore      上一步
		 * updateSelChange         表头字段下拉选择
		 * handleUpdateSave        保存导入信息
		 * updateGetNewData        获取新生成的数据
		 * updateReadFile          读取文件
		 * handleUpdateComplete    完成
		 */
		handleUpdateClose() {
            this.updateDialogObj.dialogVisible = false;
            this.updateActive = 0;
            Object.assign(this.$data.updateForm, this.$options.data().updateForm);
            this.updateForm.schoolId = this.$store.state.schoolId
			this.$refs.updateRef.$refs.importRef.$refs.upload.clearFiles();
			this.$refs.updateRef.$refs.importRef.$data.fileName = "";
		},
		downloadUpdateTemplate() {
			console.log("[更新-下载模板]this.updateForm", this.updateForm);
			if (this.updateForm.standardValueList.length == 0 || this.updateForm.renewValueList.length == 0) {
				this.$message.warning("请选择标准值及更新值");
				return;
			}
			this.updateForm.standardTextList = this.updateOptions.filter((e) => this.updateForm.standardValueList.includes(e.value)).map((e) => e.label);
			this.updateForm.renewTextList = this.updateOptions.filter((e) => this.updateForm.renewValueList.includes(e.value)).map((e) => e.label);
			console.log("更新-下载模板", this.updateForm.standardTextList, this.updateForm.renewTextList);
			downloadFile(
				{
					url: "/operate/operateTeacher/teacherUpdateTemplate",
					form: {
						standardTextList: this.updateForm.standardTextList?.join(","),
						renewTextList: this.updateForm.renewTextList?.join(","),
					},
					method: "post",
				},
				() => {},
				() => {},
			);
		},
		async updateUploadChange(file) {
			this.updateForm.file = file.raw;
			let dataBinary = await this.updateReadFile(file.raw);
			let workBook = XLSX2.read(dataBinary, {
				type: "binary",
				cellDates: true,
			});
			let workSheet = workBook.Sheets[workBook.SheetNames[0]];
			this.updateTableJsonData = XLSX2.utils.sheet_to_json(workSheet);
			this.updateUploadExcelHeader = [];
			let header = [];
			const range = XLSX2.utils.decode_range(workSheet["!ref"]);
			for (let c = range.s.c; c <= range.e.c; c++) {
				header.push(XLSX2.utils.encode_col(c) + "1");
			}
			header.forEach((item) => {
				this.updateUploadExcelHeader.push({
					label: workSheet[item].v,
					disabled: false,
				});
			});
		},
		updateUploadRemove() {
			this.updateForm.file = "";
		},
		handleUpdateNext() {
			if (this.updateForm.file === "") {
				return this.$message.warning(this.updateInfo.uploadText);
			}
			if (this.updateActive == 0) {
				if (!this.updateForm.schoolId) {
					this.$message.warning("请选择学校");
					return;
				}
				if (this.updateForm.standardValueList.length == 0 || this.updateForm.renewValueList.length == 0) {
					this.$message.warning("请选择标准值及更新值");
					return;
				}
				let standardTextList = this.updateOptions.filter((e) => this.updateForm.standardValueList.includes(e.value)).map((e) => e.label);
				let renewTextList = this.updateOptions.filter((e) => this.updateForm.renewValueList.includes(e.value)).map((e) => e.label);
				this.updateForm.renewValueList = this.updateOptions.filter((e) => this.updateForm.renewValueList.includes(e.value)).map((e) => e.value);

				let selectHeader = standardTextList.concat(renewTextList);
				let excelHeader = this.updateUploadExcelHeader.map((e) => e.label);
				let result = selectHeader.length === excelHeader.length && selectHeader.every((a) => excelHeader.some((b) => a === b)) && excelHeader.every((_b) => selectHeader.some((_a) => _a === _b));
				if (!result) {
					this.$message.error("上传文件表头与标准值及更新值不一致");
					return;
				}

				this.updateForm.standardTextList = standardTextList;
				this.updateForm.renewTextList = renewTextList;
				console.log(this.updateForm);
				this.updateGetKeyMap();
			}
			this.updateActive++;
		},
		updateGetKeyMap() {
			let requiredKeys = this.updateForm.standardTextList.concat(this.updateForm.renewTextList);
			this.updateSystemExcelHeader = this.updateOptions.map((e) => {
				return {
					label: e.label,
					required: requiredKeys.includes(e.label),
				};
			});

			this.updateSystemExcelHeader = this.updateSystemExcelHeader.filter((i) => requiredKeys.includes(i.label));

			this.updateKeyMap = {};
			this.updateSystemExcelHeader.forEach((item) => {
				let header = this.updateUploadExcelHeader.find((e) => e.label == item.label);
				this.updateKeyMap[item.label] = header?.label ?? "";
			});
		},
		handleUpdateBefore() {
			this.updateActive--;
			this.updateShowDownload = false;
		},
		updateSelChange(data) {
			this.updateUploadExcelHeader.forEach((item) => {
				let find = Object.keys(this.updateKeyMap).find((k) => this.updateKeyMap[k] === item.label);
				item.disabled = !!find;
			});
		},
		updateHeaderTypeChange(data) {
			console.log("[updateHeaderTypeChange]data", data);
			this.updateForm.standardTextList = data.filter((e) => e.type == "标准值")?.map((e) => e.label) ?? [];
			this.updateForm.standardValueList = this.updateOptions.filter((e) => this.updateForm.standardTextList.includes(e.label))?.map((e) => e.value) ?? [];
			this.updateForm.renewTextList = data.filter((e) => e.type == "更新值")?.map((e) => e.label) ?? [];
			this.updateForm.renewValueList = this.updateOptions.filter((e) => this.updateForm.renewTextList.includes(e.label))?.map((e) => e.value) ?? [];
			console.log("[updateHeaderTypeChange]this.updataForm", this.updateForm);
		},
		handleUpdateSave() {
			if (this.updateForm.standardTextList.length == 0 || this.updateForm.standardValueList.length == 0 || this.updateForm.renewTextList.length == 0 || this.updateForm.renewValueList.length == 0) {
				return this.$message.warning("请设置标准值及更新值");
			}
			// 系统必填标准字段
			const requiredField = this.updateSystemExcelHeader.filter((i) => i.required === true);
			// keyMap 选中的对应关系
			let find = requiredField.find((item) => this.updateKeyMap[item.label] === "");
			if (find) {
				return this.$message.warning("缺少必须导入字段名称");
			}
			const teacherManageModel = new SummaryTeacherBase();
			// 根据选中的字段对应关系，生成新的excel文件
			const file = this.updateGetNewData();
			let formData = new FormData();
			formData.append("file", file);
			for (let key in this.updateForm) {
				if (key !== "file") {
					if (["standardTextList", "renewTextList", "renewValueList"].includes(key)) {
						formData.append(key, this.updateForm[key].join(","));
					} else {
						formData.append(key, this.updateForm[key]);
					}
				}
			}
			this.loading = true;
            formData.append("schoolFlag", "1");
			teacherManageModel.teacherUpdateImport(formData).then((res) => {
				console.log("更新-保存", res);
				if (res.data.code === "200") {
					this.loading = false;
					this.updateActive++;
					this.updateSuccessData = res.data.data.rightCount;
                    this.getList()
				} else if (!res.data.code) {
					this.loading = false;
					this.downloadErrorListUpdate(formData);
				} else {
					this.loading = false;
					this.$message.error(res.data.msg);
				}
			});
		},
		downloadErrorListUpdate(formData) {
			this.$confirm("Excel中存在错误数据，请下载并修改", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					downloadFile(
						{
							url: "/operate/operateTeacher/teacherUpdateImport",
							method: "post",
							fileName: "信息更新错误表.xlsx",
							form: formData,
						},
						() => {},
						() => {},
					);
				})
				.catch(() => {});
		},
		updateGetNewData() {
			let newData = [];
			// 上传原excel文件数据   updateTableJsonData
			// 上传原excel表头数据   updateUploadExcelHeader
			this.updateTableJsonData.forEach((item) => {
				let obj = {};
				this.updateUploadExcelHeader.forEach((itm) => {
					const find = Object.keys(this.updateKeyMap).find((i) => this.updateKeyMap[i] === itm.label);
					if (find) {
						obj[find] = item[this.updateKeyMap[find]];
					} else {
						obj[itm.label] = item[itm.label];
					}
				});
				newData.push(obj);
			});
			const ws = XLSX2.utils.json_to_sheet(newData);
			const wb = XLSX2.utils.book_new();
			XLSX2.utils.book_append_sheet(wb, ws, "学生信息更新导入模板.xlsx");
			let arrayBuffer = XLSX2.write(wb, {
				bookType: "xlsx",
				bookSST: true,
				type: "array",
			});
			return new File([arrayBuffer], "学生信息更新导入模板.xlsx");
		},
		updateReadFile(file) {
			return new Promise((resolve) => {
				let reader = new FileReader();
				reader.readAsBinaryString(file);
				reader.onload = (ev) => {
					resolve(ev.target.result);
				};
			});
		},
		handleUpdateComplete(){
            setTimeout(()=>{
                this.getList();
            },600)
		},


        // -----------------------------------------------------
        showTableSetting() {
            this.tableSettingShow = true;
        },
        async init() {
            await this.getUserFilter();
            await this.getTableHeader();
            this.updateForm.schoolId = this.$store.state.schoolId
        },
        /**
         * @Description: 查询筛选项信息
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-12 15:59:21
         */
        async getUserFilter(){
            const summaryTeacherBase = new SummaryTeacherBase();
            await summaryTeacherBase
            .getUserPreferences({
                menuId: this.$route.meta.menuId,
                configType:'1'
            })
            .then((res) => {
                if (res.data.code == "200") {
                    this.treeScreeningData = JSON.parse(res.data.data.config)
                    console.log('查询到的筛选项信息----',this.treeScreeningData)
                    let checkSendList = this.treeScreeningData.filter(e => e.isOff == "1")
                    this.chaeckIdArr = checkSendList.map(ii=>ii.conditionId)
                    this.restaurantes = JSON.parse(res.data.data.config)
                    let filterFormData = [];
                    console.log(checkSendList,'this.treeScreeningData')
                    for (let e of checkSendList) {
                        let obj = {
                            placeholder: e.conditionName,
                            key: e.conditionId,
                            label:'',
                            value:''
                        }
                        switch(e.conditionId) {
                            case "teacherName": // 教师姓名
                                obj.type = "input"
                                break;
                            case "sex": // 性别
                                obj.type = "select"
                                obj.list = [
                                    {
                                        label: "男",
                                        value: 1,
                                    },
                                    {
                                        label: "女",
                                        value: 2,
                                    },
                                ]
                                break
                            case "phone": // 手机号
                                obj.type = "input"
                                break
                            case "onlyCode": // 工号
                                obj.type = "input"
                                break
                            case "consumNumber": // 消费账号
                                obj.type = "input"
                                break
                            case "post": // 职务
                                obj.type = "select"
                                obj.list = []
                                break
                            case "depIds": // 任职部门
                                obj.key = "departmentIds"
                                obj.type = "cascader"
                                obj.list = []
                                obj.value = []
                                obj.cascaderProps={
                                    label: "name",
                                    multiple: true,
                                    value: "id",
                                    checkStrictly: true,
                                    emitPath:false
                                }
                                break
                            case "responsibleClass": // 负责班级
                                obj.key = "responsibleClasses"
                                obj.type = "cascader"
                                obj.list = []
                                obj.value = []
                                obj.cascaderProps={
                                    label: "name",
                                    multiple: true,
                                    value: "id",
                                    checkStrictly: true,
                                    emitPath:false
                                }
                                break
                            case "subjectClass": // 任教班级
                                obj.key = "subjectClasses"
                                obj.type = "cascader"
                                obj.list = []
                                obj.value = []
                                obj.cascaderProps={
                                    label: "name",
                                    multiple: true,
                                    value: "id",
                                    checkStrictly: true,
                                    emitPath:false
                                }
                                break
                            case "subjectResponsibleClass": // 班级
                                obj.key = "subjectResponsibleClasses"
                                obj.type = "cascader"
                                obj.list = []
                                obj.value = []
                                obj.tip = "同时筛选负责班级和任课班级"
                                obj.cascaderProps={
                                    label: "name",
                                    multiple: true,
                                    value: "id",
                                    checkStrictly: true,
                                    emitPath:false
                                }
                                break
                            case "isSubjectTeacher": // 是否任课教师/是否任教
                                obj.type = "select"
                                obj.list= [
                                    {
                                        label: "否",
                                        value: 2,
                                    },
                                    {
                                        label: "是",
                                        value: 1,
                                    },
                                ]
                                break
                            case "isHeadmaster": // 是否班主任
                                obj.type = "select"
                                obj.list= [
                                    {
                                        label: "否",
                                        value: 2,
                                    },
                                    {
                                        label: "是",
                                        value: 1,
                                    },
                                ]
                                break
                            // case "roleGroup": //角色组
                            //     obj.type = 'select'
                            //     obj.collapseags = true
                            //     obj.multiple = true
                            //     obj.list = []
                            //     obj.value = []
                            case "archivesCompleteness": // 档案进度
                                obj.type = "input"
                                obj.placeholder = "档案进度小于"
                                obj.appendText= "%"
                                break
                            case "status": // 状态
                                obj.type = "select"
                                obj.list = [
                                    {label: "在职", value: "1"},
                                    {label: "离职", value: "2"},
                                ]
                                break
                            case "entryTime": // 入职日期
                                obj.type = "datePick"
                                obj.startPlaceholder = "入职日期"
                                obj.endPlaceholder = "入职日期"
                                break
                            case "powerOrganId": // 部门权限
                                obj.key = "powerOrganIds"
                                obj.type = "cascader"
                                obj.list = []
                                obj.value = []
                                obj.cascaderProps = {
                                    label: "name",
                                    multiple: true,
                                    value: "id",
                                    checkStrictly: true,
                                    emitPath:false
                                }
                                break
                            case "headImg": // 头像
                                obj.type = "select"
                                obj.list= [
                                    {
                                        label: "有",
                                        value: '1',
                                    },
                                    {
                                        label: "无",
                                        value: '0',
                                    },
                                ]
                                break
                            case "carNumber": // 车牌号
                                obj.type = "input"
                                break
                            default:
                                break
                        }
                        filterFormData.push(obj)
                    }
                    // 筛选项赋值
                    this.formData.data = filterFormData
                    console.log('赋值完成',this.formData.data)
                    // 处理重置按钮
                    // let resetBtnIndex = this.formData.btnList.findIndex(e => e.text == '重置')
                    // if (this.formData.data.length > 3) {
                    //     if (resetBtnIndex == -1) {
                    //         this.formData.btnList.push({
                    //             type: "enquiry",
                    //             text: "重置",
                    //             fn: "reset",
                    //         })
                    //     }
                    // } else {
                    //     if (resetBtnIndex > -1) {
                    //         this.formData.btnList.splice(resetBtnIndex, 1)
                    //     }
                    // }
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },

        // 任职部门选择
        changeSel(data) {
            // data.ref.dropDownVisible = false;
        },

        // 获取表头
        async getTableHeader(all) {
            // this.table_config.thead = []
            const summaryTeacherBase = new SummaryTeacherBase();
            try {
                let res = await summaryTeacherBase.getUserPreferences({
                    menuId: this.$route.meta.menuId,
                    userId: this.$store.state.userId,
                    configType: '2'
                })
                console.log("[getTableHeader]res:", res)
                if (res.data.code == '200') {
                    this.tableDensity = res.data.data.displayDensity ? res.data.data.displayDensity : '1'
                    this.tableHeaderAll = JSON.parse(res.data.data.config)
                    if (all) {
                        this.tableHeaderAll.forEach(e => {
                            e.isOff =  '1'
                        })
                    }
                    console.log("[getTableHeader]this.tableHeaderAll:", this.tableHeaderAll)
                    let tableHeaderDisplayed = this.tableHeaderAll.filter(e => e.isOff == "1")
                    let labelWidth = document.getElementsByClassName('content-wrapper')[0].offsetWidth - 170
                    let numCalculated = tableHeaderDisplayed.length // 需要计算宽度的列数
                    let useFixedWidth = false // 个别列是否使用固定宽度
                    const fixedWidthHeader = { // 固定宽度列
                        'headImg': 80,
                        'phone': 130,
                        'masterClassName': 100
                    }
                    if (numCalculated > 1) {
                        let averageWidth = labelWidth / numCalculated // 平分的宽度
                        let maxFixedWidth = -1
                        for (let headerKey in fixedWidthHeader) {
                            if (tableHeaderDisplayed.find(e => e.conditionId === headerKey)) {
                                // 计算个别列使用固定宽度时其他列的总宽度及需要计算宽度的列数
                                labelWidth -= fixedWidthHeader[headerKey]
                                numCalculated--
                                // 计算最大的固定宽度
                                if (fixedWidthHeader[headerKey] > maxFixedWidth) {
                                    maxFixedWidth = fixedWidthHeader[headerKey]
                                }
                            }
                        }
                        // 如果平均宽度比最大的固定宽度还大就使用平均宽度
                        if (averageWidth >= maxFixedWidth) {
                            labelWidth = averageWidth
                            useFixedWidth = false
                        } else {
                            labelWidth = labelWidth / numCalculated
                            useFixedWidth = true
                        }
                    }
                    // 保证最小宽度80
                    labelWidth = Math.max(labelWidth, 80)
                    // console.log("[getTableHeader]useFixedWidth:", useFixedWidth, "labelWidth:", labelWidth)
                    let tableHeader = []
                    for (let e of tableHeaderDisplayed) {
                        let obj = {
                            label: e.conditionName,
                            prop: e.conditionId,
                            labelWidth: (useFixedWidth && fixedWidthHeader[e.conditionId] ? fixedWidthHeader[e.conditionId] : labelWidth) + 'px'
                        }
                        if (e.conditionInfo) {
                            obj.labelDescription = e.conditionInfo
                        }
                        switch(e.conditionId) {
                            case "headImg": // 头像
                                obj.type = "images"
                                obj.imgErrorAdr = this.imgErrorAdr;
                                obj.align = 'center';
                                obj.className = "text-spacing-reduction"
                                obj.width = '45px'
                                obj.height = '45px'
                                obj.showPreview = true
                                break
                            case "teacherName": // 教师姓名
                                obj.type = "link"
                                obj.clickAuth = ['teacherBase:documentStu']
                                obj.align = 'center';
                                obj.className = "text-spacing-reduction"
                                obj.labelWidth = '98px'
                                break;
                            case "sex": // 性别
                                obj.type = "function"
                                obj.align = 'center';
                                obj.className = "text-spacing-reduction"
                                obj.callBack = (row) => {
                                    return {
                                        1: "男",
                                        2: "女",
                                    }[+row.sex];
                                }
                                break
                            case "phone": // 手机号
                                obj.type = "desensitize"
                                obj.align = 'center';
                                obj.auth = ["teacherBase:desensitized"]
                                obj.callBack = (row) => {
                                    if (row.phone.indexOf("*") !== -1) {
                                        this._fet(`/school/schoolTeacher/getTeacherPhone/${row.id}`, {
                                            id: row.id,
                                        },).then((res) => {
                                            if (res.data.code === "200") {
                                                row.phone = res.data.data;
                                            } else if (res.data && res.data.msg) {
                                                this.$message.error(res.data.msg);
                                            }
                                        });
                                    } else {
                                        row.phone = `${row.phone.slice(0, 3)}*****${row.phone.slice(-3)}`;
                                    }
                                }
                                break
                            case "onlyCode": // 工号
                                // obj.labelWidth = "120px"
                                obj.align = 'center';
                                obj.className = "text-spacing-reduction"
                                break
                            case "postName": // 职务
                                obj.type = "tooltipColumn"
                                obj.align = 'center';
                                obj.className = "text-spacing-reduction"
                                break
                            case "deptName": // 所属部门/任职部门
                                obj.type = "tooltipColumn"
                                obj.align = 'center';
                                obj.splitSign = ","
                                break
                            case "isSubjectTeacher": // 是否任课教师/是否任教
                                obj.type = "function"
                                obj.align = 'center';
                                obj.callBack = (row) => {
                                    return {
                                        1: "是",
                                        2: "否",
                                    }[+row.isSubjectTeacher];
                                }
                                break
                            case "subjectClassName": // 任课班级
                                obj.type = "tooltipColumn"
                                obj.align = 'center';
                                obj.splitSign = ","
                                break
                            case "subject": // 任教科目
                                obj.type = "function"
                                obj.align = 'center';
                                obj.callBack = (row) => {
                                    return this.subjects.find(
                                        (it) => it.value == row.subject,
                                    )?.label;
                                }
                                break
                            case "isHeadmaster": // 是否班主任
                                obj.type = "function"
                                obj.align = 'center';
                                obj.callBack = (row) => {
                                    return {
                                        1: "是",
                                        2: "否",
                                    }[+row.isHeadmaster];
                                }
                                break
                            case "masterClassName": // 负责班级
                                obj.type = "tooltipColumn"
                                obj.align = 'center';
                                obj.splitSign = ","
                                break
                            case "roleGroup": // 角色组
                                obj.type = "tooltipColumn"
                                obj.align = 'center';
                                obj.splitSign = ","
                                obj.labelWidth = '100px'
                                break
                            case "status": // 状态
                                obj.className = "text-spacing-reduction"
                                obj.type = "dropdown"
                                obj.align = 'center';
                                obj.list = [
                                    {label: "在职", value: "1"},
                                    {label: "离职", value: "2"},
                                ]
                                obj.style = {
                                    "2": { 'color': '#F56C6C' }
                                }
                                obj.disabled = !hasPermission(["teacherBase:edit"])
                                break
                            case "entryTime": // 入职时间
                                // obj.labelWidth = "100px"
                                obj.align = 'center';
                                break
                            case "archivesCompleteness": // 档案进度
                                obj.type = "function"
                                obj.align = 'center';
                                obj.callBack = (row) => {
                                    return row.archivesCompleteness == null ?  "-" : `${row.archivesCompleteness}%`;
                                }
                                break
                            case "powerOrganName": // 部门权限
                                obj.type = "tooltipColumn"
                                obj.align = 'center';
                                obj.splitSign = ","
                                break
                            case "carNumbers": // 车牌号
                                obj.type = "tooltipColumn"
                                obj.align = 'center';
                                obj.splitSign = ","
                                break
                            default:
                                break
                        }
                        tableHeader.push(obj)
                    }
                    tableHeader.push({
                        label: "操作",
                        type: "slot",
                        labelWidth: "77",
                        slotName: "operation",
                        renderHeader: null,
                        align: 'center',
                        className:"text-spacing-reduction"
                    }, {
                        type: 'setting'
                    })
                    this.table_config.thead = tableHeader
                    this.setTableHeight(this.tableDensity);
                    // console.log("[getTableHeader]this.table_config.thead:", this._.cloneDeep(this.table_config.thead))
                    // this.$forceUpdate();
                    // this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
                    this.$nextTick(() => {
                        this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
                        setTimeout(() => {
                            this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
                            this.$forceUpdate();
                            console.log(this.table_config.height,'this.table_config.height-1')
                            // this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
                        }, 100);
                    });
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            } catch (error) {
                console.error(error)
            }
        },
        // 设置表格每列宽度
        setTableColumnsWidth() {
            this.$nextTick(() => {
                let tableHeaderDisplayed = this.tableHeaderAll.filter(e => e.isOff == "1").map(e => e.conditionId)
                tableHeaderDisplayed = this.table_config.thead.filter(e => tableHeaderDisplayed.includes(e.prop))

                let headerKeyName;
                if (typeof tableHeaderDisplayed[0] === 'object') {
                    headerKeyName = tableHeaderDisplayed[0].hasOwnProperty('conditionId') ? 'conditionId' : 'prop'
                }

                // console.log("[setTableColumnsWidth]tableHeaderDisplayed:", tableHeaderDisplayed)
                let tableContainerWidth = document.getElementsByClassName('content-wrapper')[0].offsetWidth
                // console.log("[setTableColumnsWidth]tableContainerWidth:", tableContainerWidth)
                let labelWidth = tableContainerWidth - 170
                let numCalculated = tableHeaderDisplayed.length // 需要计算宽度的列数
                let useFixedWidth = false // 个别列是否使用固定宽度
                const fixedWidthHeader = { // 固定宽度列
                    'headImg': 80,
                    'phone': 130,
                    'masterClassName': 100
                }
                if (numCalculated > 1) {
                    let averageWidth = labelWidth / numCalculated // 平分的宽度
                    let maxFixedWidth = -1
                    for (let headerKey in fixedWidthHeader) {
                        if (tableHeaderDisplayed.find(e => e[headerKeyName] === headerKey)) {
                            // 计算个别列使用固定宽度时其他列的总宽度及需要计算宽度的列数
                            labelWidth -= fixedWidthHeader[headerKey]
                            numCalculated--
                            // 计算最大的固定宽度
                            if (fixedWidthHeader[headerKey] > maxFixedWidth) {
                                maxFixedWidth = fixedWidthHeader[headerKey]
                            }
                        }
                    }
                    // 如果平均宽度比最大的固定宽度还大就使用平均宽度
                    if (averageWidth >= maxFixedWidth) {
                        labelWidth = averageWidth
                        useFixedWidth = false
                    } else {
                        // console.log("[setTableColumnsWidth]labelWidth:", labelWidth, "numCalculated:", numCalculated)
                        labelWidth = labelWidth / numCalculated
                        useFixedWidth = true
                    }
                    // console.log("[setTableColumnsWidth]averageWidth:", averageWidth, "maxFixedWidth", maxFixedWidth)
                }
                // 保证最小宽度80
                labelWidth = Math.max(labelWidth, 80)
                // console.log("[setTableColumnsWidth]useFixedWidth:", useFixedWidth, "labelWidth:", labelWidth)
                for (let e of tableHeaderDisplayed) {
                    if (e.prop === 'teacherName') {
                        e.labelWidth = '98px'
                        return
                    }
                    e.labelWidth = (useFixedWidth && fixedWidthHeader[e[headerKeyName]] ? fixedWidthHeader[e[headerKeyName]] : labelWidth) + 'px'
                }
                // console.log("[setTableColumnsWidth]this.table_config.thead:", this._.cloneDeep(this.table_config.thead))
                this.$forceUpdate();
                this.$refs.table && this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
            })
        },

        /**
         * @Description: 筛选项的确定
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-12 18:01:11
         * @param {*} data
         */
        async handleSubmitData(data){
            // 修改筛选项条件，重置列表
            this.listQuery.entryStartTime = "";
            this.listQuery.entryEndTime = "";
            this.formData.data.forEach((item) => {
                if (Array.isArray(item.value)) {
                    item.value = [];
                } else {
                    item.value = "";
                }
                this.listQuery[item.key] = item.value;
            });
            this.getList(1);
            // console.log("[handleSubmitData]data:", this._.cloneDeep(data))
            this.treeScreeningData.forEach(item=>{
                let index = data.findIndex(i => i.conditionId === item.conditionId)
                item.isOff = index > -1 ? '1' : '0'
            })
            // console.log("[handleSubmitData]this.treeScreeningData:", this._.cloneDeep(this.treeScreeningData))

            let params = {
				schoolId: this.listQuery.schoolId,
                menuId: this.$route.meta.menuId,
                configType:'1',
				config: JSON.stringify(this.treeScreeningData),
                userId:this.$store.state.userId
			};
            // console.log('params',params.config);
            const summaryTeacherBase = new SummaryTeacherBase();
            await summaryTeacherBase
            .saveUserPreferences(params)
            .then( async (res) => {
                if (res.data.code == "200") {
                    this.$message.success('保存成功');
                    await this.getUserFilter();
                    this.getRoleList();
                    this.getOrgList();
                    this.getClassList();
                    this.getDictList();
                    await this.getNewCloseWidth();
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        getDesignList(e) {
            const summaryTeacherBase = new SummaryTeacherBase();
            summaryTeacherBase
                .getDesignList({
                    archivesTypeId: e,
                    archivesPersonType: "2",
                    schoolId: this.$store.state.schoolId,
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        this.systemExcelHeaderMore = [];
                        res.data.data.forEach((item) => {
                            let obj;
                            if (
                                item.label == "教师姓名" ||
                                item.label == "教师手机号"
                            ) {
                                obj = {
                                    label: item.label,
                                    required: true,
                                };
                            } else {
                                obj = {
                                    label: item.label,
                                    required: false,
                                };
                            }

                            this.systemExcelHeaderMore.push(obj);
                        });
                        console.log(res.data.data, "data");
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        getFileCateGory() {
            const summaryTeacherBase = new SummaryTeacherBase();
            summaryTeacherBase
                .getSelectFileCategory({
                    schoolId: this.$store.state.schoolId,
                    archivesPersonType: "2",
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        console.log(res.data.data, "res.data+++");
                        res.data.data.forEach((item) => {
                            this.$set(item, "label", item.archivesName);
                            this.$set(item, "value", item.id);
                        });
                        this.importMoreInfo.options = res.data.data;
                        // 根据权限细分筛选可导入的模块
                        // 编辑则根据职工档案编辑权限的id：03040106，在权限细分的数据中找到对应的权限
                        let summaryTeacherEditArr = [];
                        let summaryTeacherMenu = this.segmentPermList.find(item => item.menuId === this.summaryTeacherEditId);
                        if(summaryTeacherMenu && summaryTeacherMenu.permSegment == 1) {
                            this.segmentMenuList.forEach(item => {
                                if(item.menuId === this.summaryTeacherEditId) {
                                    summaryTeacherEditArr.push(item.segmentMenuId);
                                }

                            })
                            this.importMoreInfo.options = this.importMoreInfo.options.filter(item => {
                                return summaryTeacherEditArr.includes(item.id);
                            })
                            this.basicInfoEditFlag = summaryTeacherEditArr.includes(this.editBasicId);
                            this.moreInfoEditFlag = summaryTeacherEditArr.includes(this.editMoreId);
                        } else {
                            // 如果职工档案菜单没有开启权限细分，则拥有所有编辑权限
                            this.basicInfoEditFlag = true;
                            this.moreInfoEditFlag = true;
                        }
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        // 教师填写档案权限控制
        fillFormLinkShare() {
            this.$router.push({
                // path: "/summary/archives/summaryStudentInfoShare",
                name: "SummaryStudentInfoShare",
                query: {
                    schoolId: this.listQuery.schoolId,
                    type: "teacher",
                    formData: this.formData, // 保存当前查询条件
                    listQuery: this.listQuery, // 保存当前查询条件
                },
            });
        },
        // 编辑教师档案模板
        editInfoTemplate() {
            this.$router.push({
                name: "SummaryStudentInfoTemplate",
                query: {
                    schoolId: this.listQuery.schoolId,
                    type: "teacher",
                    formData: this.formData, // 保存当前查询条件
                    listQuery: this.listQuery, // 保存当前查询条件
                },
            });
        },
        filterFn(data) {
            return data.filter((i) => i.organType === "4");
        },
        /*
         * 表头颜色
         */
        initTabColor() {
            this.template_info = this.templateInfo || [];
            this.template_info.forEach((p, i) => {
                if (p.sel) {
                    this.headerColor = p.textColor;
                }
            });
        },
        changeGetList(){
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0
            });
            this.getList()
        },
        /*
         *   初始化表格
         */
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            this._fet("/school/schoolTeacher/list", this.listQuery).then(
                (res) => {
                    if (res.data.code === "200") {
                        for (let i = 0; i < res.data.data.list.length; i++) {
                            if(res.data.data.list[i].carNumbers){
                                res.data.data.list[i].carNumbers = res.data.data.list[i].carNumbers.toString();
                            }
                        }
                        const desensitization = new Desensitization();
                        this.table_data = desensitization.phone(
                            res.data.data.list,
                        );

                        this.total = res.data.data.totalCount;
                        this.loadingTable = false;
                        this.paginationIdx += 1;
                        //获取--存储筛选项及滚动条高度
                        this.$nextTick(() => {
                            let recordsList =  new RecordsList()
                            if(recordsList.get(this.$route.name)){
                                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                            }
                            console.log(recordsList.get(this.$route.name),'获取--存储筛选项及滚动条高度');

                            //移除--存储筛选项及滚动条高度
                            recordsList.remove(this.$route.name)
                            this.checkTableViewHeight();
                        });
                        console.log('有数据了')
                    } else {
                        this.$message.error("数据请求失败，请重试");
                        this.loadingTable = false;
                        this.paginationIdx += 1;
                    }
                },
            );
        },
        // 职务
        getDictList() {
            this._fet("/school/schoolDictValue/listByCondition", {
                dictKey: "teachingPost",
                schoolId: this.listQuery.schoolId,
            }).then((res) => {
                    this.postList = res.data.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }));
                    this.formData.data.forEach((item) => {
                        if (item.key == "post") {
                            item.list = this.postList;
                        }
                    });
            });
            return this._fet("/school/schoolDictValue/listByCondition", {
                schoolId: this.listQuery.schoolId,
                dictKey: "teachingSubjects",
            }).then((res) => {
                this.subjects = res.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            });
        },
        getOrgList() {
            this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.listQuery.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    const list = res.data.data.list;
                    const list1 = list.filter((it) => it.organType == 1);
                    const list2 = list.filter((it) => it.organType != 1);
                    // list2.forEach((item) => {
                    //   item.readOnly = item.organType !== '4'
                    // })
                    this.options = listToTree(list1, {
                        parentKey: "parentOrg",
                    });
                    this.formData.data.forEach((item) => {
                        if (["departmentIds", "powerOrganIds"].includes(item.key)) {
                            item.list = this.options;
                        }
                    });
                    this.options2 = listToTree(list2, {
                        parentKey: "parentOrg",
                    });
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        // 获取班级下拉
        getClassList() {
            let obj = {
                schoolId: this.listQuery.schoolId,
            };
            this._fet(
                "/school/schoolOrgan/listByCondition",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    const treeData = listToTree(res.data.data.list, {
                        parentKey: "parentOrg",
                    });
                    this.treeData.dataList = treeData.filter(
                        (i) => i.organType !== "1",
                    );
                    let jsonList = JSON.stringify(this.treeData.dataList);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.formData.data.forEach((item) => {
                        let keyAboutClassArr = ["responsibleClasses","subjectClasses","subjectResponsibleClasses"]
                        if (keyAboutClassArr.includes(item.key)) {
                            item.list = JSON.parse(jsonData);
                        }
                    });
                    // this.screen2[1].list = JSON.parse(jsonData);
                    // console.log("this.screen2[1].list",this.screen2[1].list)
                    // this.list = this.treeData.dataList
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
            // this.loadingShow = false;
        },
        /*
         *   功能按钮
         *   selAll 全选
         *   counterSel 反选
         *   cleanSel 清除
         *   add 新增
         *   importAll 导出
         *   importMore 更多信息
         */
        handleSelectionChange(data) {
            this.tableListSel = data;

            const selectedCount = data.length;
            const totalCount = this.table_data.length;
            this.checkedAll = selectedCount === totalCount;
            this.indeterminate = selectedCount > 0 && selectedCount < totalCount;
        },
        handleCheckedAll() {
            this.$refs.table.$refs.multipleTable.toggleAllSelection()
        },
        handlerTableLinkClick(ev, item, index) {
            console.log("[handlerTableLinkClick]", ev, item, index)
            if (item.prop === "teacherName") {
                this.edit(ev, 'view')
            }
        },
        dropDownClick(data) {
            console.log("列表中编辑", data);
            let params = {};
            this.$confirm(
                `确定要改为${data[1].value == 2 ? "离职" : "在职"}状态吗?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    // .map(it => it.id)
                    this._fet("/school/schoolTeacher/updateStatus", {
                        ids: [data[0].id],
                        updateStatus: data[1].value,
                    }).then((r) => {
                        if (r.data.code == 200) {
                            this.$message.success("操作成功");
                            this.getList();
                        } else {
                            this.$message.error(r.data.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        selAll() {
            let ref = this.$refs["table"].$refs.multipleTable;
            this.table_data.forEach(function (element, index) {
                ref.toggleRowSelection(element, true);
            });
        },
        counterSel() {
            let ref = this.$refs["table"].$refs.multipleTable;
            this.tableListSel = this.counter(
                this.table_data,
                this.tableListSel,
                ref,
            );
        },
        counter(data, selData, ref) {
            let arr = [];
            let arr2 = [];
            data.forEach(function (element, index) {
                const find = selData.find((it) => it.id == element.id);
                if (find) {
                    arr.push(find);
                } else {
                    arr2.push(element);
                }
            });
            this.selAll(data, ref);
            arr.forEach(function (element, index) {
                ref.toggleRowSelection(element, false);
            });
            return arr2;
        },
        cleanSel() {
            let ref = this.$refs["table"].$refs.multipleTable;
            this.tableListSel = [];
            this.table_data.forEach(function (element, index) {
                ref.clearSelection();
            });
        },
        importAll() {
            this.importDialogObj.dialogVisible = true;
        },
        importMore() {
            this.importExistingDialogObj.dialogVisible = true;
        },
        exportAll(t) {
            this.$confirm(`您将导出${t === 2 ? this.tableListSel.length : this.total}条数据，是否导出？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async() => {
                    let obj = {
                        schoolId: this.listQuery.schoolId,
                        menuId: this.$route.meta.menuId
                    };
                    if (t === 2) {
                        obj.ids = this.tableListSel.map((i) => i.id);
                        obj.identification = 2;
                    } else {
                        let filter = this.formData.data.filter((i) => i.value !== "");
                        if (filter.length === 0) {
                            obj.identification = 3;
                        } else {
                            obj.identification = 1;
                            filter.forEach((item) => (obj[item.key] = item.value));
                        }
                    }
                    this.exportLoading = true;
                    downloadFile(
                        {url: "/school/schoolTeacher/export", form: obj},
                        () => {
                            this.$message.success("导出成功");
                            this.exportLoading = false;
                        },
                        () => {},
                    );

                })
                .catch((_) => {});
        },
        acts(data) {
            if (this.tableListSel.length === 0) {
                return this.$message.warning("未选择人员信息！");
            }
            this.$confirm(
                `确定要改为${data === 2 ? "离职" : "在职"}状态吗?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    // .map(it => it.id)
                    this._fet("/school/schoolTeacher/updateStatus", {
                        ids: this.tableListSel.map((it) => it.id),
                        updateStatus: data,
                    }).then((r) => {
                        if (r.data.code == 200) {
                            this.$message.success("操作成功");
                            this.getList();
                        } else {
                            this.$message.error(r.data.msg);
                        }
                    });
                })
                .catch(() => {});
        },

        /*
         *       clickBtn 表格筛选
         *    edit表格编辑
         *
         * */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    let text = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
                    this.formData.data.forEach((item) => {
                        if (item.key !== "entryTime") {
                            this.listQuery[item.key] = item.value;
                        }
                    });
                    if (
                        this.listQuery.archivesCompleteness &&
                        !text.test(this.listQuery.archivesCompleteness)
                    ) {
                        return this.$message.error("档案填写进度请输入正数～");
                    }
                    this.listQuery.roleIdList = this.listQuery.roleGroup
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.listQuery.entryStartTime = "";
                    this.listQuery.entryEndTime = "";
                    this.listQuery.roleIdList = []
                    this.formData.data.forEach((item) => {
                        if (Array.isArray(item.value)) {
                            item.value = [];
                            this.listQuery[item.key] = [];
                        } else {
                            item.value = "";
                            this.listQuery[item.key] = "";
                        }
                    });
                    this.getList(1);
                    break;
            }
        },

        add() {
            this.pageType = "add";
            // this.dialogObj.title = "添加";
            // this.dialogObj.dialogVisible = true;

            this.$emit("change", "form", {
                pageType: this.pageType,
                schoolId: this.listQuery.schoolId,
                formData: this.formData, // 保存当前查询条件
                listQuery: this.listQuery, // 保存当前查询条件
            });
        },
        edit(id, pageType) {

            this.pageType = pageType;
            console.log(this.$route.name,this.formData, this.listQuery,'------')
            let recordsList =  new RecordsList()
            console.log(recordsList,'recordsList++')
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            console.log(recordsList.get(this.$route.name),'获取')
            this.$emit("change", "form", {
                pageType: this.pageType,
                schoolId: this.listQuery.schoolId,
                row: id,
                formData: this.formData, // 保存当前查询条件
                listQuery: this.listQuery, // 保存当前查询条件
            });
        },
        deleteInfo(row){
            this.$confirm("删除后不能恢复，您确定要删除该职工吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 确定
                    this.$confirm("删除该职工后，本系统中已经生成的统计类、记录类数据可能不能保留，并且不能恢复，确认删除该职工吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        // 确定
                        const testModel = new TestModel();
                        testModel.teacherDeleteInfo({ id:row.id,schoolId:row.schoolId}).then((res)=>{
                            if (res.data.code == "200") {
                                this.getList();
                                this.$message.success("删除成功");
                            } else {
                                this.$message.error("删除失败" );
                            }
                        })
                        // this._fet("/school/schoolTeacher/remove", {
                        //     id:row.id,
                        //     schoolId:row.schoolId
                        // }).then((res) => {
                        //     if (res.data.code == "200") {
                        //         this.getList();
                        //         this.$message.success("成功");
                        //     } else {
                        //         this.$message.error("删除失败" );
                        //     }
                        // });
                    })
                    .catch(() => {
                        // 取消
                    });
                })
                .catch(() => {
                    // 取消
                });
        },
        handleClose() {
            this.resetData();
            this.$refs.ruleForm.clearValidate();
            this.dialogObj.dialogVisible = false;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addNewSector(this.ruleForm);
                } else {
                    return false;
                }
            });
        },
        postName(e) {
            this.$nextTick(() => {
                this.ruleForm.postName = this.$refs.postSelect.selected
                    .map((i) => i.label)
                    .join(",");
            });
        },

        addNewSector(ruleForm) {
            const form = JSON.parse(JSON.stringify(ruleForm));
            if (!form.id) {
                delete form.id;
            }
            form.post = form.post.toString();
            // 获取科目名称
            let find = this.subjects.find((i) => i.value === form.subject);
            if (find) {
                form.subjectName = find.label;
            }
            if (form.isSubjectTeacher === "2") {
                form.subjectClassList = [];
                form.subjectName = "";
                form.subject = "";
            }
            if (form.isHeadmaster === "2") {
                form.manageClassList = [];
            }
            this._fet("/school/schoolTeacher/save", form).then((res) => {
                if (res.data.code == "200") {
                    this.getList();
                    this.$message.success("成功");
                    this.handleClose();
                } else {
                    this.$message.error("添加失败: " + res.data.msg);
                }
            });
        },

        resetData() {
            this.ruleForm = {
                teacherName: "",
                remark: "",
                id: "",
                schoolId: this.ruleForm.schoolId,
                phone: "",
                onlyCode: "",
                status: 1,
                classType: 1,
                orgList: [],
                sex: "1",
                post: "",
                postName: "",
                headImg: "",
                isHeadmaster: "2",
                isSubjectTeacher: "2",
                subjectClassList: [],
                manageClassList: [],
                viewList: [],
                subjectName: "",
                subject: "",
                carNumbers: []
            };
        },
        /**
         * downloadImportTemplate  下载学生成绩模板
         * importUploadRemove      删除已上传文件
         * importUploadChange      已上传文件赋值
         * handleImportNext        下一步
         * handleImportBefore      上一步
         * handleImportClose       导入弹窗关闭
         * handleImportSave        保存导入信息
         * importSelChange         表头字段下拉选择
         * getNewData              获取新生成的数据
         * readFile                读取文件
         */
        importSelChange(data) {
            this.uploadExcelHeader.forEach((item) => {
                let index = Object.keys(this.keyMap).findIndex(
                    (k) => this.keyMap[k] === item.label,
                );
                item.disabled = index > -1;
            });
        },
        importUploadRemove() {
            this.importForm.file = "";
        },
        async importUploadChange(file) {
            this.importForm.file = file.raw;
            let dataBinary = await this.readFile(file.raw);
            let workBook = XLSX2.read(dataBinary, {
                type: "binary",
                cellDates: true,
            });
            let workSheet = workBook.Sheets[workBook.SheetNames[0]];
            this.tableJsonData = XLSX2.utils.sheet_to_json(workSheet);
            let header = [];
            const range = XLSX2.utils.decode_range(workSheet["!ref"]);
            for (let c = range.s.c; c <= range.e.c; c++) {
                header.push(XLSX2.utils.encode_col(c) + "1");
            }
            // 上传文件表头
            this.uploadExcelHeader = header.map((item) => ({
                label: workSheet[item].v,
                disabled: false,
            }));
            // 系统表头
            this.systemExcelHeader.forEach((item) => {
                const index = this.uploadExcelHeader.findIndex(
                    (i) => i.label === item.label,
                );
                if (index > -1) {
                    this.keyMap[item.label] =
                        this.uploadExcelHeader[index].label;
                    this.uploadExcelHeader[index].disabled = true;
                } else {
                    this.keyMap[item.label] = "";
                }
            });
        },
        readFile(file) {
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = (ev) => {
                    resolve(ev.target.result);
                };
            });
        },
        downloadImportTemplate() {
            downloadFile({
                url: "/school/schoolTeacher/template",
                form: {
                    schoolId: this.listQuery.schoolId,
                    menuId: this.$route.meta.menuId
                }
            }, () => {}, () => {});
        },
        handleImportNext() {
            if (this.importForm.file === "") {
                return this.$message.warning("上传填好的教师信息表");
            }
            this.importActive++;
            console.log(this.importActive, "this.importActive");
        },
        handleImportBefore() {
            this.importActive--;
        },
        handleImportClose() {
            this.importDialogObj.dialogVisible = false;
            this.importActive = 0;
            this.importForm.file = "";
            this.$refs.importRef.$refs.importRef.$refs.upload.clearFiles();
            this.$refs.importRef.$refs.importRef.$data.fileName = "";
            this.getList();
        },
        /**
         * @Description: 关闭已有
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-04 10:26:31
         */
        handleImportExistingClose() {
            //this.importExistingDialogObj.dialogVisible = false;
            this.closeMore();
        },
        handleImportSave() {
            // console.log("1111111111111111", this.importLoading);
            const requireList = this.systemExcelHeader.filter(
                (i) => i.required === true,
            );
            const index = requireList.findIndex(
                (item) => this.keyMap[item.label] === "",
            );
            if (index > -1) {
                return this.$message.warning("缺少必须导入字段名称");
            }
            this.importLoading = true;
            const testModel = new TestModel();
            const file = this.getNewData();
            let formData = new FormData();
            formData.append("file", file);
            formData.append("schoolId", this.listQuery.schoolId);
            formData.append("menuId", this.$route.meta.menuId);
            formData.append("errorHandle", this.importForm.errType);
            formData.append("sameHandle", this.importForm.sameType);
            testModel
                .teacherImportInfo(formData)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.importActive++;
                        this.importSuccessData = res.data.data;
                    } else if (res.data.code === "400") {
                        this.$message.error(res.data.msg);
                    } else {
                        this.downloadErrorList(res.data);
                    }
                })
                .finally(() => {
                    this.importLoading = false;
                });
        },
        downloadErrorList(data) {
            this.$confirm(data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    downloadFile(
                        {
                            url: "/school/schoolTeacher/errorExport",
                            method: "post",
                            form: {paramData: data.info.paramData},
                        },
                        () => {},
                        () => {},
                    );
                })
                .catch(() => {});
        },
        getNewData() {
            let newData = [];
            this.tableJsonData.forEach((item) => {
                let obj = {};
                this.uploadExcelHeader.forEach((itm) => {
                    const find = Object.keys(this.keyMap).find(
                        (i) => this.keyMap[i] === itm.label,
                    );
                    if (find) {
                        obj[find] = item[this.keyMap[find]];
                    } else {
                        obj[itm.label] = item[itm.label];
                    }
                });
                newData.push(obj);
            });
            const ws = XLSX2.utils.json_to_sheet(newData);
            const wb = XLSX2.utils.book_new();
            XLSX2.utils.book_append_sheet(wb, ws, "教师信息导入模板.xlsx");
            let arrayBuffer = XLSX2.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
            });
            // XLSX2.writeFile(wb, '成绩导入模板.xlsx')
            return new File([arrayBuffer], "教师信息导入模板.xlsx");
        },

        /**
         * downloadMore 下载模版
         * nextMore 下一步
         * closeMore 关闭
         * uploadChangeMore
         * uploadRemoveMore
         * saveMore 保存导入信息
         * */
        downloadMore() {
            if (this.importMoreInfo.value) {
                downloadFile(
                    {
                        url: "/school/schoolArchivesDesign/template",
                        form: {
                            archivesTypeId: this.importMoreInfo.value,
                            archivesPersonType: "2",
                        },
                    },
                    () => {},
                );
            } else {
                this.$message.error("请选择档案类别");
            }
        },
        nextMore() {
            if (
                this.importMoreForm.file === "" ||
                this.importMoreInfo.value == ""
            ) {
                return this.$message.warning("请选择类别或上传文件～");
            }
            this.activeMore++;
        },
        closeMore() {
            this.importExistingDialogObj.dialogVisible = false;
            this.activeMore = 0;
            this.importMoreForm.file = "";
            this.importMoreInfo.value = "";
            this.$refs.importRefMore.$refs.importMoreRef.$refs.upload.clearFiles();
            this.$refs.importRefMore.$refs.importMoreRef.$data.fileName = "";
            this.getList();
        },
        async uploadChangeMore(file) {
            this.importMoreForm.file = file.raw;
            let dataBinary = await this.readFile(file.raw);
            let workBook = XLSX2.read(dataBinary, {
                type: "binary",
                cellDates: true,
            });
            let workSheet = workBook.Sheets[workBook.SheetNames[0]];
            this.tableJsonDataMore = XLSX2.utils.sheet_to_json(workSheet);
            let header = [];
            const range = XLSX2.utils.decode_range(workSheet["!ref"]);
            for (let c = range.s.c; c <= range.e.c; c++) {
                header.push(XLSX2.utils.encode_col(c) + "1");
            }
            // 上传文件表头
            this.uploadExcelHeaderMore = header.map((item) => ({
                label: workSheet[item] ? workSheet[item].v : "",
                disabled: false,
            }));
            // 系统表头
            this.systemExcelHeaderMore.forEach((item) => {
                const index = this.uploadExcelHeaderMore.findIndex(
                    (i) => i.label === item.label,
                );
                if (index > -1) {
                    this.keyMapMore[item.label] =
                        this.uploadExcelHeaderMore[index].label;
                    this.uploadExcelHeaderMore[index].disabled = true;
                } else {
                    this.keyMapMore[item.label] = "";
                }
            });
        },
        uploadRemoveMore() {
            this.importMoreForm.file = "";
        },
        saveMore() {
            let teacher = "教师姓名",
                phone = "教师手机号";
            if (!this.importMoreInfo.value) {
                return this.$message.warning("请选择档案类别");
            }
            console.log(this.keyMapMore, "this.keyMapMore");
            if (!this.keyMapMore[teacher] || !this.keyMapMore[phone]) {
                return this.$message.warning("缺少必须导入字段名称");
            }

            const requireList = this.systemExcelHeaderMore.filter(
                (i) => i.required === true,
            );
            const index = requireList.findIndex(
                (item) => this.keyMapMore[item.label] === "",
            );
            if (index > -1) {
                return this.$message.warning("缺少必须导入字段名称");
            }
            const summaryTeacherBase = new SummaryTeacherBase();

            const file = this.getNewDataMore();
            let formData = new FormData();
            formData.append("file", file);
            formData.append("schoolId", this.listQuery.schoolId);
            formData.append("archivesTypeId", this.importMoreInfo.value);
            formData.append("errType", this.importMoreForm.errType);
            formData.append(
                "archivesPersonType",
                this.importMoreForm.archivesPersonType,
            );
            console.log(formData, "formData");
            summaryTeacherBase.schoolArchivesDesign(formData).then((res) => {
                if (res.data.code === "200") {
                    this.activeMore++;
                    this.importSuccessDataMore = res.data.data.rightCount;
                    console.log(
                        this.importSuccessDataMore,
                        "this.importSuccessDataMore",
                    );
                } else if (res.data.code === "400") {
                    this.$message.error(res.data.msg);
                } else {
                    this.downloadErrorListMore(res.data);
                }
            });
        },
        downloadErrorListMore(data) {
            this.$confirm(data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    downloadFile(
                        {
                            url: "/school/schoolArchivesDesign/errorExport",
                            method: "post",
                            form: {
                                paramData: data.info.paramData,
                                archivesTypeId: "2",
                            },
                        },
                        () => {},
                        () => {},
                    );
                })
                .catch(() => {});
        },
        beforeMore() {
            this.activeMore--;
        },
        getNewDataMore() {
            let newData = [];
            this.tableJsonDataMore.forEach((item) => {
                let obj = {};
                this.uploadExcelHeaderMore.forEach((itm) => {
                    const find = Object.keys(this.keyMapMore).find(
                        (i) => this.keyMapMore[i] === itm.label,
                    );
                    if (find) {
                        obj[find] = item[this.keyMapMore[find]];
                    } else {
                        obj[itm.label] = item[itm.label];
                    }
                });
                newData.push(obj);
            });
            const ws = XLSX2.utils.json_to_sheet(newData);
            const wb = XLSX2.utils.book_new();
            XLSX2.utils.book_append_sheet(wb, ws, "教师信息导入模板.xlsx");
            let arrayBuffer = XLSX2.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
            });
            // XLSX2.writeFile(wb, '成绩导入模板.xlsx')
            return new File([arrayBuffer], "教师信息导入模板.xlsx");
        },
        importSelChangeMore(data) {
            this.uploadExcelHeaderMore.forEach((item) => {
                let index = Object.keys(this.keyMapMore).findIndex(
                    (k) => this.keyMapMore[k] === item.label,
                );
                item.disabled = index > -1;
            });
        },
        handlerSelectFileCategoryChange(e) {
            this.getDesignList(e);
        },
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
            });
        },
        datePickerBlur(e) {
            if (e && e.length > 0) {
                this.listQuery.entryStartTime = e[0] + " " + "00:00:00";
                this.listQuery.entryEndTime = e[1] + " " + "23:59:59";
            } else {
                this.listQuery.entryStartTime = "";
                this.listQuery.entryEndTime = "";
            }
        },
        inputChange(data) {
            console.log(data, "data改变");
        },

        // 表格密度修改
        handlerTableSettingDensityChange(density) {
            this.setTableHeight(density)
            this.tableDensity = density
        },
        setTableHeight(density) {
            switch (density) {
                case '1':
                    this.rowStyle.height = '56px';
                    break;
                case '2':
                    this.rowStyle.height = '48px';
                    break;
                default:
                    break;
            }
        },
        // 表格表头顺序恢复默认
        handlerTableSettingReset() {
            this.getTableHeader(true)
        },
        // 表格表头顺序改变
        handlerTableSettingSort() {
            console.log("[handlerTableSettingUpdate]this.tableHeaderAll:", this.tableHeaderAll)
            // this.getTableHeader()
            const order = this.tableHeaderAll.map(e => e.conditionId)
            this.table_config.thead.sort((a, b)=>{
                return order.indexOf(a.prop) - order.indexOf(b.prop);
            })
            console.log("[handlerTableSettingUpdate]this.table_config.thead:", this.table_config.thead)
            this.$forceUpdate();
        },
        // 表格设置关闭
        handlerTableSettingClose() {
            this.tableSettingShow = false;
        },
        // 表格设置保存
        async handlerTableSettingSave() {
            console.log("[handlerTableSettingSave]this.tableHeaderAll:", this.tableHeaderAll);
            this.tableSettingShow = false;
            const summaryTeacherBase = new SummaryTeacherBase();
            try {
                let res = await summaryTeacherBase.saveUserPreferences({
                    schoolId: this.$store.state.schoolId,
                    userId: this.$store.state.userId,
                    menuId: this.$route.meta.menuId,
                    configType: '2',
                    displayDensity: this.tableDensity,
                    config: JSON.stringify(this.tableHeaderAll)
                })
                if (res.data.code == '200') {
                    this.getTableHeader()
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            } catch (error) {
                console.error(error)
            }
        },
        /**
         * @Description: 获取最新closeWidth
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-17 14:35:20
         */
        async getNewCloseWidth () {
            this.$refs.expandFilter.setShow && this.$refs.expandFilter.setShow()
        },
        /**
         * @Description: 监听页面缩小和放大
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-08-28 09:37:39
         */
         handlerWindowSize () {
            console.log('监听页面缩小和放大')
            let _this = this;
            let lastPixelRatio = window.devicePixelRatio;
            window.addEventListener('resize', function () {
                let currentPixelRatio = window.devicePixelRatio;

                if (currentPixelRatio !== lastPixelRatio) {
                    this.setTimeout(() => {
                        _this.setTableColumnsWidth()
                    }, 100)
                }

                lastPixelRatio = currentPixelRatio;
            });

         },
        checkTableViewHeight () {
            this.$nextTick(() => {
                console.log(this.table_config.height,'checkTableViewHeight-1')
                if (!this.table_config.height || this.table_config.height == 0) {
                    this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
                    setTimeout(() => {
                        this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
                        this.$forceUpdate();
                        console.log(this.table_config.height,'checkTableViewHeight-2')
                            // this.$refs.table.$refs.multipleTable.doLayout(); // 解决表格错位
                    }, 100);
                }
            });
        },
        async createHeadImg() {


            this.$confirm(`您将生成${this.tableListSel.length ? this.tableListSel.length : this.total}条数据，是否生成？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async() => {

             // this.createHeadImgDialogObj.dialogVisible = true;
            // 检测头像生成状态
            let headImgCreateStatus = await this.getHeadImgCreateStatus();
                switch (headImgCreateStatus) {
                    case 'notStarted':
                        // 未生成则打开弹框
                        this.createHeadImgDialogObj.dialogVisible = true;
                        this.headImgFormData.isStrongDown = "2"
                        break;
                    case 'progressing':
                        this.$message({
                            message: '头像生成中，请稍后',
                            type: 'warning'
                        });
                        this.headImgFormData.isStrongDown = "2"
                        break;
                    case 'complete':
                        this.$confirm('已生成头像可点击导出，是否确认重新生成?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.headImgFormData.isStrongDown = "1"
                            this.createHeadImgDialogObj.dialogVisible = true;
                        }).catch(() => {
                            this.headImgFormData.isStrongDown = "2"
                            this.$message({
                                type: 'info',
                                message: '已取消'
                            });
                        });
                        break;

                    default:
                        break;
                }

                })
                .catch((_) => {});



         },
         handleHeadImgClose() {
            this.createHeadImgDialogObj.dialogVisible = false;
         },
         async handleImgHeadSubmit() {
            this.createHeadImgButtonLoading = true;
            let obj = {
                schoolId: this.listQuery.schoolId,
                menuId: this.$route.meta.menuId,
                nameType: this.headImgFormData.id,
                isStrongDown: this.headImgFormData.isStrongDown
            };
            let filter = this.formData.data.filter((i) => i.value !== "");
            if (this.tableListSel.length > 0) {
                obj.ids = this.tableListSel.map((i) => i.id);
                obj.identification = 2;
            } else {
                if (filter.length === 0) {
                    obj.identification = 3;
                } else {
                    obj.identification = 1;
                    filter.forEach((item) => (obj[item.key] = item.value));
                }
            }

            let res = await this._fet(`/school/schoolTeacher/exportHeadImg`,obj)

            if(res.data.code === "200"){
                this.$message.success("头像图片生成中，请稍后");
                this.createHeadImgButtonLoading = false;
                this.handleHeadImgClose();
            }
            // downloadFile(
            //     {url: "/school/schoolTeacher/exportHeadImg", form: obj},
            //     () => {
            //         this.$message.success("导出成功");
            //         this.createHeadImgButtonLoading = false;
            //         this.handleHeadImgClose();
            //     },
            //     () => {},
            // );

            // setTimeout(() => {
            //     this.createHeadImgButtonLoading = false;
            //     this.handleHeadImgClose();
            // },2000)
         },
         //  导出头像
         async exportHeadImg(){

            this.$confirm(`您将导出${this.tableListSel.length ? this.tableListSel.length : this.total}条数据，是否导出？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async() => {

            // 检测头像生成状态
            let headImgCreateStatus = await this.getHeadImgCreateStatus();
                switch (headImgCreateStatus) {
                    case 'notStarted':
                        // 未生成
                        this.$message({
                            message: '请先生成头像，再导出',
                            type: 'warning'
                        });
                        break;
                    case 'progressing':
                        this.$message({
                            message: '头像生成中，请稍后',
                            type: 'warning'
                        });
                        break;
                    case 'complete':
                        let res = await this._fet("/school/schoolTeacher/downloadTeaImg",{schoolId:this.schoolId})
                        if(res.data.code === "200"){
                            window.open(res.data.data)
                        }
                        break;

                    default:
                        break;
                }

                })
                .catch((_) => {});


         },
         /**
         * 检测头像生成状态
         */
        async getHeadImgCreateStatus(){
            let res = await  this._fet(`/school/schoolTeacher/checkDownStatus`,{schoolId:this.schoolId})
            let code = res.data.code;
            // 未完成、进行中、已完成
            let switchObj = {
                "200":"notStarted",//未开始
                "210002":"progressing",//进行中
                "210003":"complete"//已完成
            }

            let finalStatus = switchObj[code]
            return finalStatus
        },
        //获取角色组下拉数据
        getRoleList() {
            this.roleList = []
            this._fet("/school/schoolRole/getRoleList", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    let data = res.data.data;
                    data = data.map(item =>{
                        return {
                            label:item.roleName,
                            value:item.id
                        }
                    })
                    this.roleList = data;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }

                this.formData.data.forEach((item) => {
                    if (item.key == 'roleGroup') {
                        item.type = 'select'
                        item.list = this.roleList;
                        item.collapseags = true
                        item.multiple = true
                        item.value = []
                    }
                });
                console.log(this.formData.data,'this.formData.data')
            });
        },
    },
    watch: {
        templateInfo: {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true,
        },
        'mainContainer.sideBarWd': {
            handler() {
                setTimeout(() => {
                    this.setTableColumnsWidth()
                }, 300)
            }
        }
    },
};
</script>

<style scoped lang="scss">
.inline {
    margin: 4px 10px;
    width: 1px;
    height: 24px;
    background-color: #E6E6E6;
}
.right-panel {
    padding-right: 10px;

    .el-icon-question {
        &:hover{
            color: #5cb6ff;
            cursor: pointer;
        }
    }
}
.w2 {
    width: 48%;
    float: left;
    margin-right: 2%;
    box-sizing: border-box;
}

//
//.w3 {
//  width: 33%;
//  display: inline-block;
//}

.lt {
    width: 30%;
    padding-right: 40px;
    box-sizing: border-box;
    float: left;
}

.rt {
    width: 70%;
    float: left;
}

.tac {
    text-align: center;
}

.labelWidth {
    max-width: 280px;
    display: inline-block;
}
.el-dropdown-menu {
    width: 111px;
}

.el-dropdown-menu__item {
    padding: 0 10px;

    &:hover {
        background-color: #fff;
    }
}

.item-wrapper {
    display: inline-block;
    width: 100%;
    margin-top: -5px;
}

.tips {
    font-size: 12px;
    display: block;
    margin-top: -5px;
    height: 25px;
    color: #909399;
}
/deep/.mSelect {
    display: block;
    max-width: 280px;
    margin-top: 30px;
}

.table-setting {
    ::v-deep .el-drawer__header {
        display: none;
    }
}

.pagination-box {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: 42px;

    .pagination-left {
        position: absolute;
        left: 0;
        top: 0;
        padding: 2px 18px;
        height: 32px;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .pagination-left-label {
            color: #333333;
            font-size: 14px;
            margin-right: 16px;
        }

        @each $type, $color in (default: #3C7FFF, primary: #3C7FFF, danger: #F57171, warning: #EFBE28) {
            .custom-button-#{$type} {
                height: 28px;
                padding: 0 16px !important;
                border-color: if($type == default, #C7C7C7, $color) !important;

                ::v-deep span {
                    color: if($type == default, #333333, $color);
                }

                &:hover {
                    background-color: if($type == default, #ffffff, $color);
                    border-color: $color !important;

                    ::v-deep span {
                        color: if($type == default, $color, #ffffff);
                    }
                }
            }
        }
    }

    .pagination-container {
        height: auto;
    }
}

.import-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto
}
</style>
<style>
.teacher-import-popover {
    padding: 0 !important;
    min-width: 0 !important;
}
</style>
